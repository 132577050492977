import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Medical Coding Advisor",
	desc: "Chat with our Medical Coding AI",
	category: "Advisor",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "gray-500",
	toColor: "gray-500",

	to: "/ai/medcodechat",
	api: "/ai/medcodechat",

	output: {
		title: "Medical Coding Advisor",
		desc: "Advisor Response. You are advised that this AI-generated content may contain errors and requires your review.",
		// Icon: false,
		// color: "blue",
	},

	prompts: [{
		title:"Your Questions for the Advisor",
		desc: "A sentence or paragraph you wish to pose to the AI Advisor. ",
		// n: 1,
		prompts: [
			{ 
				title: "Enter your question", 
				attr: "chatcontent",  
				value: "", 
				placeholder: "Describe the key elements of Medical Coding", 
				label: "Pose your question",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				// min: 5,
				required: true,
				error: "",
				example: "Describe the key elements of Medical Coding",
			},
		],

	example: {
		outputs: [
			"Hello World ",
		],
		color: "blue",
	}
}]
}

export default obj

