import React, { Component } from "react";
import {
	PencilIcon,
	ClockIcon,
	InformationCircleIcon,
	DuplicateIcon,
} from "@heroicons/react/outline";

import { Helmet } from "react-helmet";

import Header from "../Components/Header";
import Body, { Grid, Col } from "../Components/Body";
import Button from "../Components/Button";
import Output from "../Components/Output";
import Countdown from "react-countdown";
import { withRouter } from "react-router-dom";

import { observable, makeObservable, computed, action } from "mobx";
import { observer, inject } from "mobx-react";

import EntryTabs from "../Components/EntryTabs";
import EntryPrompt from "../Components/EntryPrompt";
import EntryInput from "../Components/EntryInput";
import EntryN from "../Components/EntryN";
import toast from "react-hot-toast";

import Filter from "bad-words";
let filterBadWords = new Filter();

@inject("store")
@observer
class Tool extends Component {
	@observable tool = {};

	@observable.deep prompts = [];
	@observable currentPrompt = 0;
	@observable currentOption = "Start Using";
	@observable previewValue = "";

	@observable error = "";

	@observable output = "";
	@observable outputs = [];
	@observable code = "";

	@observable loading = false;

    @observable feedbackId = ""
    @observable feedbackValue = ""

	@observable selectedFile = null;

	@observable date = Date.now() + 1000;
	@observable audioProcessor = {};

	countdown = [];

	constructor(props) {
		super(props);
		makeObservable(this);
		this.tool = this.props.store.getToolByUrl(this.props.location.pathname);
		if (!this.tool) {
			window.location.href = "/";
		} else {
			this.prompts = [...this.tool.prompts];
		}
    }

    handleCurrentPrompt = (val) => {
		this.currentPrompt = val
        this.feedbackId = ""
        this.feedbackValue = ""
	}

	@computed get isGenerateButtonDisabled() {
		if (this.loading) {
			return true;
		}

		return false;
	}

	@computed get disabled() {
		if (this.prompts[this.currentPrompt].prompts[0].value.length < 1) {
			return true;
		}

		// this.prompts[this.currentPrompt].prompts[promptIndex].value
		return false;
	}

	@computed get isMinLength() {
		if (!this.props.prompt.min) {
			return false;
		}
		if (!this.props.prompt.type === "number") {
			return false;
		}

		return false;
	}

	checkMinimumPrompts = () => {
		let shouldReturn = false;

		this.prompts[this.currentPrompt].prompts.forEach(
			(prompt, promptIndex) => {
				if (prompt.min) {
					if (prompt.value.length < prompt.min) {
						shouldReturn = true;
						prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
					}
				}
			}
		);

		return shouldReturn;
	};

	clearExampleTimeout = [];

	onStartUsing = async () => {
		this.loading = false;
		this.error = "";
		this.clearExampleTimeout.forEach((item, index) => {
			clearTimeout(this.clearExampleTimeout[index]);
		});
		this.currentOption = "Start Using";
	};

	onExample = async () => {
		this.loading = true;
		this.error = "";
		this.output = "";
		this.outputs = [];
		this.code = ``;

		this.currentOption = "Example";

		this.prompts[this.currentPrompt].prompts.forEach(
			(prompt, promptIndex) => {
				this.prompts[this.currentPrompt].prompts[promptIndex].value = prompt.example;

				if (this.prompts[this.currentPrompt].prompts[promptIndex].options &&
					this.prompts[this.currentPrompt].prompts[promptIndex].options.length > 0
				) {
					this.prompts[this.currentPrompt].prompts[promptIndex].options = this?.prompts[this.currentPrompt]
					?.prompts[promptIndex]
					?.options
					?.map((option) => ({ ...option, checked: false }));
				}
			}
		);

		if (this.prompts[this.currentPrompt].example.output) {
			this.output = this.prompts[this.currentPrompt].example.output;
		}

		if (this.prompts[this.currentPrompt].example.code) {
			this.code = `${this.prompts[this.currentPrompt].example.code}`;
		}

		if (this.prompts[this.currentPrompt].example.outputs) {
			this.outputs = this.prompts[this.currentPrompt].example.outputs;
		}

		this.loading = false;
		this.feedbackId = "";
        this.feedbackValue = "";
	};

	sanitizeAllPrompts = () => {
		this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
			if (!prompt.value) {
				return false;
			}
			if (prompt.type === "number") {
				return false;
			}

			prompt.value = prompt.value.trim();

			if (filterBadWords.isProfane(prompt.value)) {
				prompt.error = "Unsafe content , please try different language";
				throw Error("Unsafe content");
			}
		});
	};

	contentFilterFlagged = async (response) => {
		this.error = response.message;

		this.date = Date.now() + 5000;
		this.countdown.forEach((countdown) => {
			if (countdown) {
				countdown.stop();
				countdown.start();
			}
		});
		this.loading = false;
	};

	checkOutput = (output) => {
		if (output) {
			output = output.replace(/^\s+|\s+$/g, "");
			// output = output.replace(/\s{2,}/g, ' ')
		}
		return output;
	};

	@computed get language() {
		let language = "";
		this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
			if (prompt.attr === "language") {
				language = `${prompt.value}`;
			}
		});
		return language;
	}

	setPreviewValue = (value) => {
		this.previewValue = value;
	};

	setFeedbackValue = (value) => {
        this.feedbackValue = value
    };

	setLoading = (value) => this.loading = value

	onGenerateClick = async () => {
		try {
			this.error = "";
			this.output = "";
			this.code = ``;
			this.outputs = [];
			this.loading = true;

			let checkMinimumPrompts = this.checkMinimumPrompts();
			if (checkMinimumPrompts) {
				this.loading = false;
				return false;
			}
			// this.sanitizeAllPrompts()

			let postObj = {};

			this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
				postObj[prompt.attr] = prompt.value;
			});

			postObj.currentPrompt = this.prompts[this.currentPrompt].title;
			if (this.prompts[this.currentPrompt].n) {
				postObj.n = this.prompts[this.currentPrompt].n;
			}

			let response;

			if (this.selectedFile) {
				const formData = new FormData();

				// Update the formData object
				formData.append("file", this.selectedFile);
				Object.entries(postObj).forEach(([key, value]) => {
					formData.append(key, value);
				});

				response = await this.props.store.api.post(
					this.tool.api,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);
			} else {
				response = await this.props.store.api.post(
					this.tool.api,
					postObj
				);
			}

			if (!response.data.success) {
				toast.error(response.data.message, {
					position: "top-center",
				});

				return false;
			}

			if (response.data.output) {
				this.output = this.checkOutput(response.data.output);
			}

			if (response.data.code) {
				this.code = response.data.code;
			}

			if (response.data.outputs) {
				this.outputs = response.data.outputs;
			}

			if (response.data.feedbackId) {
                this.feedbackId = response.data.feedbackId
            }

			/** Audio Transcriber */
			if (response.data.currentPrompt && response.data.currentPrompt === "Audio Upload") {
				this.audioProcessor = {
					currentPrompt: response.data.currentPrompt,
					numberOfSpeakers: response.data?.numberOfSpeakers ?? 0,
					...(response.data.utterances && { utterances: response.data.utterances }),
					...(response.data.entities && { entities: response.data.entities }),
				}
			}

			this.date = Date.now() + 10000;
			this.countdown.forEach((countdown) => {
				if (countdown) {
					countdown.stop();
					countdown.start();
				}
			});
		} catch (error) {
			console.log(error);
			this.countdown.forEach((countdown) => {
				if (countdown) {
					countdown.stop();
					countdown.start();
				}
			});
		} finally {
			this.loading = false;
		}
	};

	render() {
		// required for mobx to pick up deeply nested value
		const currentValue = this.prompts[this.currentPrompt].prompts[0].value;

		return (
			<>
				<Helmet>
					<title>{`${this.tool.title} Tools - MedcodeAI`}</title>
				</Helmet>
				<Header
					title={this.tool.title}
					desc={this.tool.desc}
					Icon={this.tool.Icon}
					fromColor={this.tool.fromColor}
					category={this.tool.category}
					options={[
						{
							title: "Start Using",
							Icon: PencilIcon,
							color: this.props.store.profile.credits
								? "green"
								: "red",
							onClick: this.onStartUsing,
						},
						{
							title: "Example",
							color: "yellow",
							Icon: InformationCircleIcon,
							onClick: this.onExample,
						},
					]}
					currentOption={this.currentOption}
				/>
				<Body>
					<Grid>
						<Col span="6">
							<EntryTabs
								prompts={this.prompts}
								currentPrompt={this.currentPrompt}
								onChange={this.handleCurrentPrompt}
							/>

							{this.prompts.map((prompt, index) => (
								<EntryPrompt
									prompt={prompt}
									key={index}
									index={index}
									disabled={this.disabled}
									currentPrompt={this.currentPrompt}
								>
									{(() => {
										// Create a 2D array (matrix) to represent the table
										let tableMatrix = [];

										prompt.prompts
											.filter((promptInput) => promptInput.displayintable)
											.forEach((promptInput) => {
												if (!tableMatrix[promptInput.row]) {
													tableMatrix[
														promptInput.row
													] = [];
												}

												tableMatrix[promptInput.row][promptInput.column] = (
													<EntryInput
														prompt={promptInput}
														key={`${promptInput.row}-${promptInput.column}`}
														language={this.language}
														index={`${promptInput.row}-${promptInput.column}`}
														disabled={this.disabled}
														onPreviewValue={this.setPreviewValue}
														previewValue={this.previewValue}
														onSelectedFile={(file) => (this.selectedFile = file)}
														onLoading={this.setLoading}
													/>
												);
											});

										return (
											<div>
												{
													// First, render prompts that don't have `displayintable` set to true
													prompt.prompts.filter((promptInput) => !promptInput.displayintable)
														.map((promptInput, index)  => (
																<EntryInput
																	prompt={promptInput}
																	key={index}
																	language={this.language}
																	index={index}
																	disabled={this.disabled}
																	onPreviewValue={this.setPreviewValue}
																	previewValue={this.previewValue}
																	onSelectedFile={(file) => (this.selectedFile = file)}
																	onLoading={this.setLoading}
																/>
															)
														)
												}

												{
													// Then render the table from the matrix
													<table>
														<tbody>
															{tableMatrix.map((row, rowIndex) => (
																	<tr key={rowIndex}>
																		{row.map((cell, cellIndex) => (
																				<td key={cellIndex}>
																					{cell}
																				</td>
																			)
																		)}
																	</tr>
																)
															)}
														</tbody>
													</table>
												}
											</div>
										);
									})()}

									<div className="md:flex">
										<Button
											title={"Perform Request"}
											disabled={this.isGenerateButtonDisabled}
											Icon={currentValue ? DuplicateIcon : PencilIcon}
											onClick={this.onGenerateClick}
										/>
										<EntryN
											prompts={this.prompts}
											currentPrompt={this.currentPrompt}
										/>
									</div>
									{this.audioProcessor?.currentPrompt === "Audio Upload" && this.audioProcessor?.numberOfSpeakers >= 2 && (
										<SpeakerInputs 
											numberOfSpeakers={this.audioProcessor.numberOfSpeakers}
											replaceSpeaker={(speaker, newSpeaker) => {
												this.outputs = this.outputs.map(output => output.replace(new RegExp(speaker, 'g'), newSpeaker))
											}}
										/>
									)}
									{this.audioProcessor?.currentPrompt === "Audio Upload" && this.audioProcessor?.entities && (
										<div className="grid grid-cols-2 gap-x-5 gap-y-3 my-2">
											{
												Object.entries(this.audioProcessor.entities).map(([category, items], index) => (
													<div key={index} className="p-4">
													<h3 className="text-lg leading-6 font-medium text-gray-900">
														{category.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
													</h3>
													<ul>
														{items.map((item, index) => (
															<li key={index} className="list-disc list-inside">{item}</li>
														))}
													</ul>
													</div>
												))
											}
									  	</div>
									)}
								</EntryPrompt>
							))}
						</Col>
						<Col span="6">
							<Output
								title={this.tool.output.title}
								desc={this.tool.output.desc}
								Icon={this.tool.output.Icon || this.tool.Icon}
								fromColor={this.tool.fromColor}
								toColor={this.tool.toColor}
								loading={this.loading}
								output={this.output}
								outputs={this.outputs}
								code={this.code}
								language={this.language}
								outputsColor={this.tool.output.color}
								OutputsIcon={this.tool.output.Icon}
								{...this.audioProcessor}
								feedbackId={this.feedbackId}
								handleFeedbackValue={this.setFeedbackValue}
								feedbackValue={this.feedbackValue}
							/>
						</Col>
					</Grid>
				</Body>
			</>
		);
	}
}


const SpeakerInputs = ({numberOfSpeakers, replaceSpeaker}) => {
	const speakers = [];
	const [inputsValue, setInputsValue] = React.useState(Array(numberOfSpeakers).fill(""));

	const handleBlur = (e, i) => {
		const input = e.target.value;

		let updatedValues = [...inputsValue];
		updatedValues[i] = `${input}:`;

		if (input === "") return;

		if (inputsValue[i] === "") {
			replaceSpeaker(new RegExp(`Speaker ${String.fromCharCode(i + 97).toUpperCase()}:`, 'g'), `${input}:`);	
			setInputsValue(updatedValues);
			return;
		}

		replaceSpeaker(inputsValue[i], `${input}:`);
		setInputsValue(updatedValues);
	}

	for (let i = 0; i < numberOfSpeakers; i++) {
		speakers.push(
			<div key={i} className="my-2">
				<label 
					htmlFor={`speaker-${i}`} 
					className="relative transition text-gray-600 focus-within:text-gray-800 block mt-2"
				>
					Speaker {String.fromCharCode(i + 97).toUpperCase()}
				</label>
				<input type="text"
					id={`speaker-${i}`}
					className={`outline-none focus:outline-none text-sm bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400 font-regular transition-all`}
					onBlur={(e) => handleBlur(e, i)}
				/>
			</div>
		);
	}

	return <div className="flex flex-col mt-2">
		<h2 className="text-lg leading-6 font-medium text-gray-900">
			Change Speaker Labels
		</h2> 
		{speakers}
	</div>
}

export default withRouter(Tool);
