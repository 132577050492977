import React, { useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";

const EntryAudio = inject("store")(
	observer(({ store, audioUrl }) => {
		const audioRef = useRef(null);
		const [start, end] = store.audioTimeStamp;
		const { isPlaying } = store.audioStatus;

		useEffect(() => {
			if (audioRef.current && start !== undefined && end !== undefined) {
				const audioElement = audioRef.current;
				audioElement.currentTime = start;

				if (isPlaying) audioElement.play()
				else audioElement.pause();

				const handleTimeUpdate = () => {
					if (audioElement.currentTime >= end) {
						audioElement.pause();
						store.setAudioStatus({ isPlaying: false });
						store.removeAudioTimeStamp();
					}
				};

				audioElement.addEventListener("timeupdate", handleTimeUpdate);

				return () => {
					audioElement.removeEventListener(
						"timeupdate",
						handleTimeUpdate
					);
				};
			}
		}, [audioUrl, start, end, isPlaying]);

		return (
			<audio
				ref={audioRef}
				controls
				src={audioUrl}
				className="w-full my-2"
			/>
		);
	})
);

export default EntryAudio;
