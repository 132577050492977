import progressnoterewriter from './progressnoterewriter';
import summarize from './summarize';
import blogwriter from './blogwriter';
import medicalcoding from './medicalcoding';
import medcodechat from './medcodechat';
import clinicalnoteswriter from './clinicalnoteswriter';
import talktodatabase from './talktodatabase';
import audios from './audio'
// import chatbot from './chatbot';

const TOOLS = [
	// blogwriter,
	// progressnoterewriter,
	// summarize,
	medicalcoding,
	medcodechat,
	// clinicalnoteswriter,
	// talktodatabase,
	// ...audios
	// chatbot,
]

export default TOOLS
