import React from "react";

const extractValues = (obj) => {
	let result = [];
	Object.keys(obj).forEach((key) => {
		result = [...result, ...obj[key]];
	});
	return result;
};

const getHighlightedText = (text, higlights = []) => {
	// Split text on higlight term, include term itself into parts, ignore case
	let parts = text.split(new RegExp(`(${higlights.join("|")})`, "gi"));
	return parts.map((part, i) => (
		<span
			key={i}
			style={
				higlights.includes(part)
					? {
							backgroundColor: "yellow",
							fontWeight: "bold",
					  }
					: {}
			}
		>
			{part}
		</span>
	));
};

function TextHighLighter({ output, entities = {} }) {
	return <span>{getHighlightedText(output, extractValues(entities))}</span>;
}

export default TextHighLighter;
